.blockBtn_wrapper > div {
  display: flex;
  gap: 0.1rem;
}
.blockBtn_wrapper > div > button {
  padding: 0 0.4rem !important;
  font-size: 0.7rem;
  border: none;
  border-radius: 0.4rem;
}
.blockBtn_wrapper > div > button:nth-child(1) {
  /* background-color: green; */
  color: #111;
}
.blockBtn_wrapper > div > button:nth-child(2) {
  /* background-color: red; */
  color: #111;
}
