.section {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.3rem;
    border-bottom: none;
  }
  .section > div {
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0.5rem;
  }
  .section > div > p {
    color: #a8a9b4;
  }
  .section > div > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    color: #6d6f80;
  }
  .image_container {
    display: flex;
    justify-content: space-between;
    /* flex-direction: column; */
  }
  .image_container > div {
    width: 100px;
    height: 100px;
    border-radius: 0.3rem;
    /* background-color: orange; */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    position: relative;
    cursor: pointer;
  }
  .image_container > div > img {
    position: absolute;
    width: 100%;
  }
  